<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-01-24 09:21:19
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-14 10:32:32
 * @FilePath: /impact-iotos-console/src/views/login/components/phoneLogin.vue
-->
<template>
  <div class="pwd-container">
    <div class="input-container" :class="errMsg.identifier ? 'border-red' : ''">
      <img src="@/assets/user_icon.png" class="left-icon">
      <input type="text" class="user-input" placeholder="请输入手机号" v-model="ruleForm.mobile" @keyup.enter="submit">
    </div>
    <div class="error-box">{{ errMsg.identifier }}</div>
    <div class="input-container" :class="errMsg.code ? 'border-red' : ''">
      <img src="@/assets/pwd_icon.png" class="left-icon">
      <input type="text" class="user-input-code" placeholder="请输入验证码" v-model="ruleForm.mobileCode"
        @keyup.enter="submit">
      <div class="code-btn" @click="getverification" :class="disabled ? 'code-btn-clicked' : ''">{{ codeMsg }}</div>
    </div>
    <div class="error-box">{{ errMsg.code }}</div>
    <div class="submit-btn" @click="submit" v-preventReClick>登 录</div>
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import { ref, reactive } from 'vue';
import api from "@/api/api"
import { useRouter } from "vue-router";
const errMsg = reactive({
  identifier: "",
  code: ""
})
const router = useRouter();
// 验证码倒计时
const codeNum = ref(60);
// 获取验证码按钮禁用
const disabled = ref(false);
// 获取验证码按钮文字
const codeMsg = ref("获取验证码");
// 倒计时是否进行
// const countdownIng = ref(false);
const ruleForm = reactive({
  mobile: "",
  mobileCode: "",
  // clientId: 2,
  // mobileCodeSign: "",
});

const getverification = () => {
  const reg = /^\d{11}$/;
  let value = ruleForm.mobile;
  //校验手机号是否输入及正确
  if (!value) {
    // 如果不通过校验则不处理
    ElMessage.error("请输入手机号！")
  } else if (!reg.test(value)) {
    ElMessage.error("手机号格式错误！")
  } else if (codeNum.value === 60 && !disabled.value) {
    //可以进行短信发送
    disabled.value = true;
    api.getSmsCode(value).then(({ code, res }) => {
      if (code === "0") {
        ElMessage.success("发送成功");
        const { mobileCodeSign } = res.data;
        ruleForm.mobileCodeSign = mobileCodeSign;
        let timer = setInterval(() => {
          --codeNum.value;
          sessionStorage.setItem("codeNum", codeNum.value);
          codeMsg.value = `重新发送${codeNum.value}`;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          codeNum.value = 60;
          disabled.value = false;
          codeMsg.value = "获取验证码";
        }, 60000);
      } else {
        disabled.value = false;
      }
    });
  }
};
const submit = () => {
  if (!ruleForm.mobile || !ruleForm.mobileCode) return;
  const { mobile, mobileCode } = ruleForm;
  if (!mobile) {
    errMsg.identifier = "手机号不能为空！"
    return;
  } else {
    errMsg.identifier = ""
  }
  if (!mobileCode) {
    errMsg.code = "验证码不能为空！"
    return;
  } else {
    errMsg.code = ""
  }
  api.mobileLogin({
    identifier: mobile,
    code: mobileCode
  }).then(res => {
    if (res.code === "0") {
      ElMessage.success("登录成功！")
      const { access_token, token_type } = res.res.data;
      if (!access_token) return;
      const token = token_type + " " + access_token
      const token_url = process.env.VUE_APP_TOKEN
      localStorage.setItem(token_url, token);
      getUserInfo()
    } else {
      // todo 提示
      console.log(res);
    }
  })
}

const getUserInfo = () => {
  api.getUserProfile().then(res => {
    if (res.code === "0") {
      const userInfo = res.res.data;
      const token_url = process.env.VUE_APP_TOKEN
      localStorage.setItem(token_url + "userInfo", JSON.stringify(userInfo));
      router.push("/");
    }
  })
}
</script>

<style lang="less" scoped>
.pwd-container {
  box-sizing: border-box;
  // padding: 0 30px;
}

.input-container {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #E9ECF0;
  box-sizing: border-box;
  padding: 0 14px;
  padding-right: 0;
  display: flex;
  align-items: center;
  // margin-bottom: 20px;
  justify-content: space-between;

  .left-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .user-input {
    width: 350px;
    background: transparent;
    height: 36px;
    border: none;
    outline: none;
    font-size: 16px;
    color: #5C5C5C;
  }

  .user-input-code {
    width: 300px;
    background: transparent;
    height: 36px;
    border: none;
    outline: none;
    font-size: 16px;
    color: #5C5C5C;
  }

  .code-btn {
    width: 77px;
    height: 100%;
    background: #367CC5;
    border-radius: 0px 3px 3px 0px;
    text-align: center;
    line-height: 36px;
    font-size: 12px;
    font-weight: lighter;
    color: #ffffff;
    cursor: pointer;
  }

  .code-btn-clicked {
    color: #FFFFFF;
    background: #949CA6;
  }
}

.error-box {
  margin-top: 5px;
  margin-bottom: 15px;
  color: red;
  font-size: 14px;
  height: 5px;
}

.border-red {
  border-color: red;
}

.submit-btn {
  cursor: pointer;
  margin-top: 50px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(to top, #355BA2, #6289C4);
  text-align: center;
  box-sizing: border-box;
  line-height: 40px;
  font-weight: lighter;
  font-size: 18px;
  color: #ffffff;
}

input::placeholder {
  color: #ADB7CA;
  font-size: 16px;
  line-height: 36px;
}

input ::-webkit-input-placeholder {
  color: #ADB7CA;
  font-size: 26px;
}

input :-moz-placeholder {
  color: #ADB7CA;
  font-size: 26px;
}

input :-ms-input-placeholder {
  color: #ADB7CA;
  font-size: 26px;
}
</style>