<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-01-24 09:21:19
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-07 14:55:03
 * @FilePath: /impact-iotos-console/src/views/login/components/pwdLogin.vue
-->
<template>
  <div class="pwd-container">
    <div class="input-container" :class="errMsg.idMsg ? 'border-red' : ''">
      <img src="@/assets/user_icon.png" class="left-icon">
      <input type="text" class="user-input" placeholder="请输入用户名/手机号" v-model="query.identifier" @keyup.enter="submit">
    </div>
    <div class="error-box">{{ errMsg.idMsg }}</div>
    <div class="input-container" :class="errMsg.pwdMsg ? 'border-red' : ''">
      <img src="@/assets/pwd_icon.png" class="left-icon">
      <input type="password" class="user-input" placeholder="请输入密码" v-model="query.password" @keyup.enter="submit">
    </div>
    <div class="error-box">{{ errMsg.pwdMsg }}</div>
    <div class="submit-btn" @click="submit" v-preventReClick>登 录</div>
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import api from "@/api/api"
import { reactive } from 'vue';
import { useRouter } from "vue-router";
const router = useRouter();
const query = reactive({
  identifier: "",
  password: "",
})
const errMsg = reactive({
  idMsg: "",
  pwdMsg: "",
})
const submit = () => {
  if (!query.identifier) {
    errMsg.idMsg = "用户名/手机号不能为空！"
    return;
  } else {
    errMsg.idMsg = ""
  }
  if (!query.password) {
    errMsg.pwdMsg = "密码不能为空！"
    return;
  } else {
    errMsg.pwdMsg = ""
  }
  api.authLogin(query).then(res => {
    if (res.code === "0") {
      ElMessage.success("登录成功！")
      const { access_token, token_type } = res.res.data;
      if (!access_token) return;
      const token = token_type + " " + access_token
      const token_url = process.env.VUE_APP_TOKEN
      localStorage.setItem(token_url, token);
      getUserInfo()
    } else {
      // todo 提示
      console.log(res);
    }
  })
}

const getUserInfo = () => {
  api.getUserProfile().then(res => {
    if (res.code === "0") {
      const userInfo = res.res.data;
      const token_url = process.env.VUE_APP_TOKEN
      localStorage.setItem(token_url + "userInfo", JSON.stringify(userInfo));
      router.push("/");
    }
  })
}
</script>

<style lang="less" scoped>
.pwd-container {
  box-sizing: border-box;
}

.input-container {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #E9ECF0;
  box-sizing: border-box;
  padding: 0 14px;
  display: flex;
  align-items: center;

  .left-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .user-input {
    width: 350px;
    background: transparent;
    height: 36px;
    border: none;
    outline: none;
    font-size: 16px;
    color: #5C5C5C;
  }
}

.error-box {
  margin-top: 5px;
  margin-bottom: 15px;
  color: red;
  font-size: 16px;
  height: 5px;
}

.border-red {
  border-color: red;
}

.submit-btn {
  cursor: pointer;
  margin-top: 50px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(to top, #355BA2, #6289C4);
  text-align: center;
  box-sizing: border-box;
  line-height: 40px;
  font-weight: lighter;
  font-size: 18px;
  color: #ffffff;
}

input::placeholder {
  color: #ADB7CA;
  font-size: 16px;
  line-height: 36px;
}

input ::-webkit-input-placeholder {
  color: #ADB7CA;
  font-size: 26px;
}

input :-moz-placeholder {
  color: #ADB7CA;
  font-size: 26px;
}

input :-ms-input-placeholder {
  color: #ADB7CA;
  font-size: 26px;
}
</style>