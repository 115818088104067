<template>
  <div class="login" id="home">
    <div class="logo-box">
      <img src="@/assets/logo1.png">
      <div>物联⽹平台</div>
    </div>
    <img src="@/assets/icon-bg.png" class="icon_bg" />
    <!-- 密码登录 -->
    <div class="slogin">通过物联网技术连接世界</div>
    <div class="form-container">
      <div class="tabs-container">
        <div :class="loginType === 1 ? '' : 'no_select'" @click="changeLoginType(1)">账号登录</div>
        <div :class="loginType === 2 ? '' : 'no_select'" @click="changeLoginType(2)">手机登录</div>
      </div>
      <pwdLogin v-if="loginType === 1" />
      <phoneLogin v-if="loginType === 2" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import pwdLogin from "./components/pwdLogin";
import phoneLogin from "./components/phoneLogin";
const loginType = ref(sessionStorage.getItem("changeLoginType") * 1 || 1);
const changeLoginType = (v) => {
  if (loginType.value === v) return;
  loginType.value = v;
  sessionStorage.setItem("changeLoginType", v);
};
</script>

<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background: #fff;
  background: url("~@/assets/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.icon_bg {
  position: absolute;
  width: 455px;
  height: 461px;
  left: 192px;
  top: 245px;
}

.form-container {
  position: absolute;
  width: 380px;
  top: 351px;
  left: 1255px;
}

.tabs-container {
  box-sizing: border-box;
  padding: 0 7px 0 7px;
  border-bottom: 1px solid #E9ECF0;
  display: flex;
  font-size: 24px;
  color: #333333;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 31px;

  &>div {
    box-sizing: border-box;
    padding-bottom: 17px;
    border-bottom: 5px solid #367CC5;
  }

  &>div:last-child {
    margin-left: 36px;
  }

  .no_select {
    font-weight: lighter;
    color: #949CA6;
    border-bottom: 0;
  }
}

.slogin {
  position: absolute;
  left: 230px;
  top: 778px;
  font-size: 36px;
  line-height: 50px;
  color: #fff;
}

.logo-box {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50px;
  top: 50px;

  img {
    width: 36px;
    height: 32px;
    margin-right: 8px;
  }

  div {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
  }
}
</style>